import React from "react";
import { Card, Row, Col } from "antd";
import "../assets/Tools.scss";
import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
axios.defaults.withCredentials = true;

const Tools = () => {
  const [tool, setTools] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `https://my-blog-cpmores-gpvl.vercel.app/api/tools`
        );
        setTools(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="tools-container">
      <h1 className="tools-title">My Tool Collection</h1>
      {!tool ? (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
      ) : (
        <Row gutter={[24, 24]} justify="center">
          {[...Array(11)].map((_, index) => (
            <Col xs={24} sm={12} md={8} lg={6} key={index}>
              {tool[index] && (
                <Link
                  to={`${tool[index].url}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Card
                    hoverable
                    title={`${tool[index].name}`}
                    className="tool-card"
                  >
                    <p>Card content for tool {index + 1}</p>
                    <p>{tool[index].desc}</p>
                  </Card>
                </Link>
              )}
            </Col>
          ))}
        </Row>
      )}
    </div>
  );
};

export default Tools;
