import React, { useContext } from "react";
import "../assets/_footer.scss";
import { AuthContext } from "../context/authContext";

export default function Home() {
  const { isDarkMode } = useContext(AuthContext);
  return (
    <main>
      <div
        className="foo"
        style={{
          backgroundColor: isDarkMode ? "#6d597a" : "#9d8189", // 动态背景色
          color: isDarkMode ? "#fff" : "#e6ebe0",
        }}
      >
        <div className="at">© 2024 CPMORES, All Rights Reserved</div>
      </div>
    </main>
  );
}
