import React, { useEffect, useState } from "react";
import "../assets/home.scss";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { Button, Card, Typography, Flex } from "antd";

axios.defaults.withCredentials = true;

const cardStyle = {
  width: "calc(33.33% - 20px)", // 设置卡片宽度为三分之一减去间距
  margin: "10px",
  border: "none",
  boxShadow: "none",
};
const topCardStyle = {
  ...cardStyle,
  height: "400px",
  width: "auto",
  marginLeft: "10vw",
  marginRight: "10vw",
  marginTop: "100px",
  marginBottom: "50px",
  display: "flex", // 使用 Flexbox 布局
};
const imgStyle = {
  display: "block",
  width: "100%",
  height: "200px",
  objectFit: "cover",
  border: "2px solid #c0c0c0",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
};
const topImgStyle = {
  ...imgStyle,
  width: "450px",
  height: "100%",
  maxHeight: "350px",
};

const para = {
  height: "100px",
  width: "100%",
  fontSize: "16px",
  fontFamily: "Roboto, sans-serif",
  overflow: "hidden",
};

export default function Home() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const cat = useLocation().search;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `https://my-blog-cpmores-gpvl.vercel.app/api/posts${cat}`
        );
        setPosts(res.data);
      } catch (err) {
        setPosts([]);
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [cat]);

  return (
    <main className="homepage">
      {loading ? (
        <Spin
          style={{
            height: "60vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
        />
      ) : (
        <>
          {/* 顶端卡片 */}
          {posts.length > 0 && (
            <Card
              hoverable
              style={topCardStyle}
              key={posts[posts.length - 1]?.id}
            >
              <Flex
                justify="space-between"
                style={{
                  width: "100%",
                }}
              >
                <img
                  alt={posts[posts.length - 1]?.title}
                  src={posts[posts.length - 1]?.img}
                  style={topImgStyle}
                />
                <Flex
                  vertical
                  align="flex-end"
                  justify="space-between"
                  style={{
                    padding: 32,
                  }}
                >
                  <Typography.Title level={2}>
                    {posts[posts.length - 1]?.title}
                  </Typography.Title>
                  <p style={para}>{posts[posts.length - 1]?.desc}</p>
                  <Button type="primary" className="but">
                    <Link
                      to={`/post/${posts[posts.length - 1]?.id}`}
                      style={{ color: "white" }}
                    >
                      Learn More
                    </Link>
                  </Button>
                </Flex>
              </Flex>
            </Card>
          )}

          {/* 其他卡片 */}
          <div className="posts">
            {!cat
              ? posts
                  .slice(-7, -1)
                  .reverse() // 选取倒数第七到倒数第二的post
                  .map((post) => (
                    <Card hoverable style={cardStyle} key={post.id}>
                      <img alt={post.title} src={post.img} style={imgStyle} />
                      <div style={{ padding: 16 }}>
                        <Typography.Title level={3}>
                          {post.title}
                        </Typography.Title>
                        <Button type="primary" className="but">
                          <Link
                            to={`/post/${post.id}`}
                            style={{ color: "white" }}
                          >
                            Learn More
                          </Link>
                        </Button>
                      </div>
                    </Card>
                  ))
              : posts.reverse().map((post) => (
                  <Card hoverable style={cardStyle} key={post.id}>
                    <img alt={post.title} src={post.img} style={imgStyle} />
                    <div style={{ padding: 16 }}>
                      <Typography.Title level={3}>
                        {post.title}
                      </Typography.Title>
                      <Button type="primary" className="but">
                        <Link
                          to={`/post/${post.id}`}
                          style={{ color: "white" }}
                        >
                          Learn More
                        </Link>
                      </Button>
                    </div>
                  </Card>
                ))}
          </div>
        </>
      )}
    </main>
  );
}
