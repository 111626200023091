import React from "react";
import "../assets/_menu.scss";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import PostSearch from "./SearchPost";

export default function Menu({ cat }) {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `https://my-blog-cpmores-gpvl.vercel.app/api/posts?cat=${cat}`
        );
        setPosts(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [cat]);

  return (
    <div className="menu">
      <PostSearch />
      <h1>Other Posts you may like</h1>
      {posts.slice(0, 4).map((post) => (
        <div className="post" key={post.id}>
          <img src={post.img} alt="failed" />
          <h2>{post.title}</h2>
          <button>
            <Link to={`/post/${post.id}`}>Read More</Link>
          </button>
        </div>
      ))}
    </div>
  );
}
