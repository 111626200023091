import React, { useEffect, useState } from "react";
import { AutoComplete, Spin } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom"; // Fixed: Use 'react-router-dom'

const PostSearch = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onSelect = (value) => {
    const selectedPost = posts.find((post) => post.title === value);
    if (selectedPost) {
      navigate(`/post/${selectedPost.id}`);
    }
  };

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true); // 在请求开始时设为 true
      try {
        const response = await axios.get(
          "https://my-blog-cpmores-gpvl.vercel.app/api/posts"
        );
        setPosts(response.data);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
      setLoading(false); // 请求结束后设为 false
    };
    fetchPosts();
  }, []);

  // Format posts for AutoComplete options
  const options = posts.map((post) => ({
    label: post.title, // 显示在 AutoComplete 列表中
    value: post.title, // 用作搜索的值
  }));

  return (
    <div>
      {loading ? (
        <Spin />
      ) : (
        <AutoComplete
          style={{
            width: "100%",
          }}
          options={options} // 设置 AutoComplete 的选项
          placeholder="Search posts"
          onSelect={onSelect}
          filterOption={(inputValue, option) =>
            option.value.toUpperCase().includes(inputValue.toUpperCase())
          }
        />
      )}
    </div>
  );
};

export default PostSearch;
