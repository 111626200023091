import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";
import rehypeRaw from "rehype-raw";
import rehypeKatex from "rehype-katex";
import "katex/dist/katex.min.css";
import "../assets/mdeditor.scss";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";

// 自定义的 iframe 组件
const IframeComponent = ({ src }) => (
  <div style={{ textAlign: "center", margin: "0 auto" }}>
    <iframe
      src={src}
      title="Custom Iframe"
      style={{
        width: "90%",
        maxWidth: "800px",
        height: "500px",
        border: "none",
        display: "block",
        margin: "0 auto",
      }}
    />
  </div>
);

// 自定义的图片组件，支持 zoom
const ZoomableImage = ({ src, alt, zoom }) => {
  return (
    <div style={{ textAlign: "center" }}>
      <img
        src={src}
        alt={alt}
        style={{
          width: `${zoom}%`, // 使用 zoom 参数设置宽度
          maxWidth: "80%",
          height: "auto",
          cursor: "pointer",
        }}
      />
    </div>
  );
};

const MdEditor = ({ content }) => {
  const CodeBlock = ({ children, className }) => {
    const language = className?.replace("language-", "") || "plaintext";

    return (
      <SyntaxHighlighter language={language} style={docco}>
        {children}
      </SyntaxHighlighter>
    );
  };

  // 自定义渲染器
  const components = {
    code: CodeBlock,
    iframe: IframeComponent,
    img: ({ node }) => {
      const style = node.properties.style || {};
      const zoom = style.zoom ? parseFloat(style.zoom) : 100; // 默认 zoom 为 100%

      return (
        <ZoomableImage
          src={node.properties.src}
          alt={node.properties.alt}
          zoom={zoom}
        />
      );
    },
  };

  return (
    <div className="markdown-container">
      <ReactMarkdown
        components={components}
        remarkPlugins={[remarkGfm, remarkMath]}
        rehypePlugins={[rehypeRaw, rehypeKatex]}
      >
        {content}
      </ReactMarkdown>
    </div>
  );
};

export default MdEditor;
